$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                scrollTo(target);
                return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements() {
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    var $heroSlider = $(".js-heroslider"),
        $heroSliderWrapper = $heroSlider.parents(".js-heroslider-wrapper");

    if ($heroSlider.length) {
        $heroSlider.slick({
            infinite: true,
            fade: true,
            dots: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
            waitForAnimate: false,
            prevArrow: $heroSliderWrapper.find(".js-heroslider-nav-prev"),
            nextArrow: $heroSliderWrapper.find(".js-heroslider-nav-next"),
            draggable: false,
            responsive: [],
            rows: 0,
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productCarousel
    // *
    var $productCarousel = $(".js-productcarousel"),
        $productCarouselWrapper = $productCarousel.parents(".js-productcarousel-wrapper");

    if ($productCarousel.length) {
        $productCarousel.slick({
            infinite: true,
            fade: false,
            dots: false,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
            waitForAnimate: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $productCarouselWrapper.find(".js-productcarousel-nav-prev"),
            nextArrow: $productCarouselWrapper.find(".js-productcarousel-nav-next"),
            draggable: false,
            responsive: [
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            rows: 0,
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productslider
    // *
    var $productslider = $(".js-productslider"),
        $productsliderWrapper = $productslider.parents(".js-productslider-wrapper");

    if ($productslider.length) {

        $productslider.on('init reInit afterChange', function (event, slick) {

            currentSlideIndex = slick.currentSlide + 1
            var totalSlides = slick.slideCount;

            $productsliderWrapper.find(".js-productslider-preview img").removeClass("active")
            $productsliderWrapper.find(".js-productslider-preview img").eq(slick.currentSlide).addClass("active")
            // Update the custom pagination control with the current format
            $('.slick-dots').text(currentSlideIndex + '/' + totalSlides);
        });

        $(".js-productslider-previewimage").click(function () {
            var index = $(this).index();
            $productslider.slick('slickGoTo', index);
        })



        $productslider.slick({
            infinite: true,
            fade: true,
            dots: true,
            appendDots: $(".js-productslider-dots"),
            arrows: true,
            autoplay: false,
            autoplaySpeed: 5000,
            waitForAnimate: false,
            prevArrow: $productsliderWrapper.find(".js-productslider-nav-prev"),
            nextArrow: $productsliderWrapper.find(".js-productslider-nav-next"),
            draggable: false,
            responsive: [],
            rows: 0,


        });



    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navMobile
    // *
    $(".js-navmobile-button").click(function () {
        $(".js-navmobile").toggleClass("active");
    });

    $(".js-navmobile-subicon").click(function () {
        $(this).parents("li").toggleClass("open").find("ul").slideToggle();
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    var $imageSlider = $(".js-imageslider"),
        $imageSliderWrapper = $imageSlider.parents(".js-imageslider-wrapper");

    if ($imageSlider.length) {
        $imageSlider.slick({
            infinite: true,
            fade: true,
            dots: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000,
            waitForAnimate: false,
            prevArrow: $imageSliderWrapper.find(".js-imageslider-nav-prev"),
            nextArrow: $imageSliderWrapper.find(".js-imageslider-nav-next"),
            draggable: false,
            responsive: [],
            rows: 0,
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * quantity
    // *
    // *
    $(".js-quantity-button").click(function () {
        var $parent = $(this).parents(".js-quantity"),
            $input = $parent.find(".js-quantity-input"),
            value = parseInt($input.val());

        if ($(this).data("type") == 'minus') {
            value--;
            if (value <= 1) value = 1;
        }

        if ($(this).data("type") == 'plus') {
            value++;
        }

        $input.val(value);
    });



     // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popUP
    // *
    // *
    // trigger active class on click and when no cookie is set
    const popupClose = document.querySelector(".js-close-popup");
    let popupState = sessionStorage.getItem('popupState');

    if (popupClose) {
        popupClose.addEventListener("click", function () {
            sessionStorage.setItem('popupState', true);
            this.closest(".js-popup").classList.toggle("active");
        });
    }
    if (!popupState) {
        document.querySelector('.js-popup') !== null ? document.querySelector('.js-popup').classList.add('active') : undefined;
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal window
    // *
    // *
    $(window).on('hashchange load', function () {
        var hash = window.location.hash.split('#')[1],
            $modal = $('[data-modal="' + hash + '"]');

        if ($modal.length) {
            $(".js-modal").fadeOut();
            $modal.fadeToggle();
        }
    });


    // close modal
    $(".js-modal-button").click(function () {
        $(this).parents(".js-modal").fadeToggle();
        window.location.hash = '#';

        // refresh page
        if (modalRefresh == true) {
            location.reload();
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter products
    // *
    // *
    $(".js-filter-item").click(function (event) {
        event.preventDefault();

        var url = $(this).attr("href"),
            $products = $(".js-product-wrapper");

        // mark filter as active
        $(this).addClass("active").siblings().removeClass("active");

        // change url
        history.pushState({}, '', url)

        // empty products
        $products.html('');

        // load filtered content
        $.get(url).done(function (data) {
            $(data).find(".js-product-wrapper .js-product").appendTo($products);
        });
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * hide add cart quantity flag if emepty
    // *
    // *
    if ($(".js-cart-total").html() <= 0) {
        $(".js-cart-notify").hide();

    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video
    // *
    // *
    $(".js-video").click(function () {
        var video = $(this).find("video").get(0);
        if (video.paused) {
            video.play();
            $(this).addClass("active");
        }
        else {
            video.pause();
            $(this).removeClass("active");
        }

    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-item").click(function () {
        $(this).toggleClass("active").find(".js-accordion-content").slideToggle();

    });

      // * * * * * * * * * * * * * * * * * * * * * * * * *
    // *fancybox
    // *
    // *
    $('[data-fancybox="js-productImageSlider"]').fancybox({
        buttons : {
            play : false,
            thumbs: false
        }
    });

});




// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 100) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
